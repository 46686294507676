import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/images/Logo01.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Novi uvoz za module
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import dron from "../../../assets/images/drone.jpg"

const Navbar = (props) => {
  const images = [dron];

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-blue">
        <div className="container">
          <NavLink to="/" className="navbar-brand">
            <img src={Logo} alt="rc.in.rs logo" className="logo" /> 
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/about-us" className="nav-link active" aria-current="page">
                  O nama
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link active" aria-current="page">
                  Kontakt
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container">
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        loop={true}
        modules={[Navigation, Pagination, Autoplay]}
      >
        {images.map((src, index) => (
          <SwiperSlide key={index}>
            <img src={src} alt={`Slika ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
          </SwiperSlide>
        ))}
      </Swiper>
      </div>
    </>
  );
};

export default Navbar;


