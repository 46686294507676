import { useParams } from "react-router-dom";

const Event = () => {
  const { eventId } = useParams();

  return (
    <div className="container-fluid">
      <div className="container">Event: {eventId}</div>
    </div>
  );
};

export default Event;
