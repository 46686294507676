import { Routes, Route, BrowserRouter } from "react-router-dom";
import axios from "axios";

import Navbar from "./shared/components/Navbar/Navbar";
import Footer from "./shared/components/Footer/Footer";

import Home from "./pages/home/Home";
import AboutUs from "./pages/aboutUs/AboutUs";
import Contact from "./pages/contact/Contact";
import Events from "./pages/events/Events";
import Event from "./pages/events/Event";
import VerifyEmail from "./pages/verifyEmail/VerifyEmail"

//axios.defaults.baseURL = "https://api.rc.in.rs";
axios.defaults.baseURL = "http://localhost:5000/";
axios.defaults.headers.common['Content-Type'] = 'application/json';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/events" element={<Events />} />
          <Route path="/event/:eventId" element={<Event />} />
          <Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
