const Events = () => {
    return(
        <div className="container-fluid">
            <div className="container">
                Events
            </div>
        </div>
    )
}

export default Events;