import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/images/Logo.png"

const Footer = (props) => {
  return (
    <div className="container-fluid bg-light">
      <div className="container">
        <div className="footer-logo">
          <img src={Logo} alt="rc.in.rs"/>
        </div>
        <div className="footer">
          <p className="text-center">
            © Copyright 2024 RC.IN.RS. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
