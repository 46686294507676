import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { FaRegTimesCircle, FaRegCheckCircle } from "react-icons/fa";

const VerifyEmail = () => {
  const { token } = useParams();
  
  // State variables
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const sendToken = async () => {
    try {
      const response = await axios.post("/web/user/verify-email", {token: token});
      if (response.status === 200) {
        setIsVerified(true); 
      }
    } catch (err) {
      setIsVerified(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("TEST")
    sendToken();
  }, []);

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="box-verified">
          {isLoading && (
            <div className="spinner-border verified-spinner" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          {!isLoading && isVerified && (
            <div className="verified">
              <FaRegCheckCircle className="text-success" />
              <p>Email verified successfully!</p>
            </div>
          )}
          {!isLoading && !isVerified && (
            <div className="verified">
              <FaRegTimesCircle  className="text-danger"/>
              <p>Failed to verify email.</p>
            </div>
          )}
        </div>

        <div className="container p-5">
          <NavLink className="nav-link text-center" to="/">Idi na pocetnu</NavLink>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;

