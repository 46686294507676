import React from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../shared/UI/Button"

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      title: "",
      message: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Unesite ispravnu email adresu")
        .required("Email adresa je obavezna"),
      title: Yup.string().required("Naslov je obavezan"),
      message: Yup.string()
        .required("Poruka je obavezna")
        .min(10, "Poruka mora imati barem 10 karaktera"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post("/web/contact", values);
        if (response.data.isSuccess) {
          formik.resetForm();
          console.log("Poruka je uspešno poslata.");
        }
      } catch (error) {
        if (error.response) {
          // Server je odgovorio sa statusom različitim od 2xx
          console.log("Greška sa servera:", error.response.data);
        } else if (error.request) {
          // Zahtev je poslat ali nije bilo odgovora
          console.log("Nema odgovora sa servera:", error.request);
        } else {
          // Nešto drugo je pošlo po zlu prilikom slanja zahteva
          console.log("Greška:", error.message);
        }
      }
    },
  });

  return (
    <div className="container-fluid">
      <div className="container p-4">
        <div className="row">
          <div className="col-sm-12 col-md-6 p-4"></div>
          <div className="col-sm-12 col-md-6 p-4">
            <h5>Kontakt forma</h5>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email adresa:
                </label>
                <input
                  type="email"
                  className={`form-control ${
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : ""
                  }`}
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Naslov:
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    formik.touched.title && formik.errors.title
                      ? "is-invalid"
                      : ""
                  }`}
                  id="title"
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="invalid-feedback">{formik.errors.title}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Poruka:
                </label>
                <textarea
                  rows="3"
                  className={`form-control ${
                    formik.touched.message && formik.errors.message
                      ? "is-invalid"
                      : ""
                  }`}
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message ? (
                  <div className="invalid-feedback">
                    {formik.errors.message}
                  </div>
                ) : null}
              </div>

              <div className="mb-3">
                <Button
                  label="Pošalji"
                  type="submit"
                  className="btn btn-primary"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
